import {
  SoccerGame,
  Team,
  NotificationBundle,
  NotificationType,
  PushNotificationSettingToRespect,
  LowPriorityNotificationDetail,
  LowPriorityNotificationDetailType,
  CalendarEntry,
  CalendarEntryGameScrimmage,
  TeamFeatures,
  EvaluationId,
  PlayerBundleId,
  PlayerBundle__AccountType,
  AccountId
} from "@ollie-sports/models";
import { fetchAccountIdsOnSquad } from "../../internal-utils/team-utils";
import { generatePushID } from "../../internal-utils/firebaseId";
import moment from "moment";
import { processNotificationBundles } from "./notification.plumbing";
import { getUniversalHelpers } from "../../helpers";
import { validateSelfAccountId, validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import { timeHasPassedSinceScheduledGameStart } from "../../compute";
import { playerBundle__server__getPrettyPlayerBundle } from "../playerBundle";
import { ServerThisContext } from "@ollie-sports/react-bifrost";
import { fetchAccountPrivatesCached } from "../../utils/date-helpers";
import _ from "lodash";
import { translate } from "@ollie-sports/i18n";

export async function notification__server__triggerForEvaluationCoach(
  this: ServerThisContext,
  p: {
    evaluationId: EvaluationId;
    coachAccountId: AccountId;
    accountIdsToNotify: { accountId: AccountId; type: "managingAccount" | "coach" | "orgAdmin" }[];
  }
) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const now = Date.now();

  const [evaluation, coachAccount, coachAccountPrivate] = await Promise.all([
    h.Evaluation.getDoc(p.evaluationId),
    h.Account.getDoc(p.coachAccountId),
    (async () => (await fetchAccountPrivatesCached({ accountIds: [p.coachAccountId] }))[0])()
  ]);

  if (!evaluation || !coachAccount) {
    throw new Error("Invalid ids supplied to notification__server__triggerForEvaluationCoach");
  }

  if (!evaluation.sentAtMS) {
    throw new Error(
      "Can't send notification for evaluation that isn't complete in notification__server__triggerForEvaluationCoach"
    );
  }

  const evaluatorAccount = await h.Account.getDoc(evaluation.evaluatorId);

  const accountPrivates = _.compact(await fetchAccountPrivatesCached({ accountIds: p.accountIdsToNotify.map(a => a.accountId) }));

  const types = _.uniq(p.accountIdsToNotify.map(a => a.type)).filter(a => a !== "managingAccount");
  const expireAtMS = moment().add(30, "days").valueOf();

  const communicationLocales = _.uniq(accountPrivates.map(ap => ap.communicationLocale));
  const lpNotificationsByLocaleThenByType: Record<
    string,
    Record<string, LowPriorityNotificationDetail>
  > = communicationLocales.reduce((acc, locale) => {
    types.forEach(type => {
      if (type === "managingAccount") {
        return;
      }
      const lp: LowPriorityNotificationDetail = {
        body: translate(
          {
            defaultMessage: `{name} has completed a new coach evaluation for {coachName}.`,
            serverLocale: locale
          },
          {
            name: evaluatorAccount
              ? `${evaluatorAccount.firstName} ${evaluatorAccount.lastName}`
              : this.translate({ defaultMessage: "A coach", serverLocale: locale }),
            coachName: `${coachAccount.firstName} ${coachAccount.lastName}`
          }
        ),
        title: translate({
          defaultMessage: `New Coach Evaluation!`,
          serverLocale: locale
        }),
        id: h.LowPriorityNotificationDetail.generateId(),
        routerPath:
          type === "orgAdmin"
            ? `main/tools/orgSettings/evaluations/${evaluation.orgId ?? "noOrgId"}/${p.evaluationId}`
            : `main/teams/${evaluation.teamId}/noOrgId/noOpenAction/evaluations/${p.evaluationId}`,
        createdAtMS: now,
        type: LowPriorityNotificationDetailType.newEvaluation,
        expireAtMS
      };
      if (!acc[locale]) {
        acc[locale] = { [type]: lp };
      } else {
        acc[locale] = { ...acc[locale], ...{ [type]: lp } };
      }
    });
    return acc;
  }, {} as Record<string, Record<string, LowPriorityNotificationDetail>>);

  const allLowPriortyNotifications = _.flatten(Object.values(lpNotificationsByLocaleThenByType).map(a => Object.values(a)));

  await Promise.all(
    allLowPriortyNotifications.map(lp => {
      return h.LowPriorityNotificationDetail.add({ doc: lp });
    })
  );

  const title = this.translate({
    defaultMessage: `New Coach Evaluation!`,
    serverLocale: coachAccountPrivate?.communicationLocale ?? "en-us"
  });
  const body = this.translate(
    {
      defaultMessage: `{name} has completed a new coach evaluation for you.`,
      serverLocale: coachAccountPrivate?.communicationLocale ?? "en-us"
    },
    {
      name: evaluatorAccount
        ? `${evaluatorAccount.firstName} ${evaluatorAccount.lastName}`
        : this.translate({ defaultMessage: "A coach", serverLocale: coachAccountPrivate?.communicationLocale ?? "en-us" })
    }
  );

  const triggerEventId = evaluation.id + Date.now();

  const notificationBundles: NotificationBundle[] = _.compact(
    p.accountIdsToNotify
      .map(a => ({ accountId: a.accountId, id: generatePushID(), type: a.type }))
      .map(a => {
        if (a.type === "managingAccount") {
          return {
            type: NotificationType.evaluationCoach,
            id: a.id,
            accountId: a.accountId,
            triggerEventId,
            pushNotificationData: {
              body,
              title: title,
              id: a.id,
              pushNotificationSettingToRespect: PushNotificationSettingToRespect.ALWAYS_SEND,
              triggerEventId,
              type: NotificationType.evaluationCoach,
              evaluationId: p.evaluationId,
              coachAccountId: a.accountId
            },
            realTimeNotification: {
              d: now,
              id: a.id,
              t: NotificationType.evaluationCoach,
              e: moment().add(30, "days").valueOf(),
              eId: p.evaluationId,
              caId: a.accountId
            }
          };
        } else {
          const communicationLocale = accountPrivates.find(ap => ap.id === a.accountId)?.communicationLocale ?? "en-us";
          const lpnd = lpNotificationsByLocaleThenByType[communicationLocale][a.type];
          if (lpnd) {
            return {
              type: NotificationType.lowPriorityNotification,
              id: a.id,
              accountId: a.accountId,
              triggerEventId,
              pushNotificationData: {
                body: lpnd.body,
                title: lpnd.title,
                id: a.id,
                pushNotificationSettingToRespect: PushNotificationSettingToRespect.ALWAYS_SEND,
                type: NotificationType.lowPriorityNotification,
                triggerEventId,
                routerPath: lpnd.routerPath,
                lowPriorityNotificationDetailType: lpnd.type
              },
              realTimeNotification: {
                d: now,
                e: expireAtMS,
                id: a.id,
                t: NotificationType.lowPriorityNotification,
                lpId: lpnd.id
              }
            };
          }
        }
        return null;
      })
  );

  await processNotificationBundles({
    notificationBundles
  });
  // SERVER_ONLY_TOGGLE
}

notification__server__triggerForEvaluationCoach.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
