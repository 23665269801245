import {
  Conversation,
  Message,
  NotificationBundle,
  CONVERSATION_TYPES,
  NotificationType,
  TEAM_CONVERSATION_TYPES,
  TEAM_PERMISSIONS,
  PushNotificationSettingToRespect,
  ORG_CONVERSATION_TYPES,
  Team__StaffTypes,
  AccountId,
  Account
} from "@ollie-sports/models";

import { pluck } from "../../internal-utils/misc";
import * as _ from "lodash";
import shortid from "shortid";
import { getUniversalHelpers } from "../../helpers";

import { fetchAccountIdsOnSquad } from "../../internal-utils/team-utils";
import { extactSquadKey, extactSquadSubset, isSquadConversation } from "../../compute/conversation.compute";
import { hasPermissionOnTeam } from "../../compute/teamLegacy.compute";
import { getListOfAccountIdsForOrgChatChannel } from "../../compute/org.compute";
import moment from "moment";
import { conversation__server__accountIds } from "../conversation";
import { processNotificationBundles } from "./notification.plumbing";
import { translate } from "@ollie-sports/i18n";
import { accountPrivate__server__setConversationUpdatedAtMSForAccounts } from "../account/accountPrivate__setConversationUpdatedAtMSFoRAccounts";

export async function sendNotificationsForNewMessage(p: { selfAccountId: string; messageId: string }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  // Fetch the message
  const message = await h.Message.getDoc(p.messageId);
  if (!message) {
    throw new Error("Attempting to generate notifications for a message that does not exist.");
  }
  // Make sure this is the owner of the message
  if (message.accountId !== p.selfAccountId) {
    throw new Error("Attempting to create a notification for a message. Requestor is not the message owner");
  }

  // Fetch the conversation
  const conversation = await h.Conversation.getDoc(message.conversationId);
  if (!conversation) {
    throw new Error("Attempting to generate notifications for a message. Conversation does not exist.");
  }

  const notificationBundles = await generateNotificationBundlesForMessage({
    conversation,
    message,
    selfAccountId: p.selfAccountId
  });

  await processNotificationBundles({
    notificationBundles
  });

  const accountIds = [p.selfAccountId, ...notificationBundles.map(n => n.accountId)];

  await Promise.all(
    accountIds.map(accountId => {
      return accountPrivate__server__setConversationUpdatedAtMSForAccounts({
        accountId,
        convoId: conversation.id
      });
    })
  );
  // SERVER_ONLY_TOGGLE
}
export async function generateNotificationBundlesForMessage(p: {
  conversation: Conversation;
  message: Message;
  selfAccountId: AccountId;
}): Promise<NotificationBundle[]> {
  // SERVER_ONLY_TOGGLE
  if (p.conversation.conversationType === CONVERSATION_TYPES.accounts) {
    return await generateNotificationsForNewMessagePrivateConversations(p);
  } else if (p.conversation.conversationType === CONVERSATION_TYPES.team) {
    return await generateNotificationsForNewMessageTeamConversations(p);
  } else if (p.conversation.conversationType === CONVERSATION_TYPES.org) {
    return await generateNotificationsForNewMessageOrgConversations(p);
  } else {
    getUniversalHelpers().olliePipe.emitEvent({ type: "error-unhandled-message-type-for-notifications", payload: p });
    return [];
  }
  // SERVER_ONLY_TOGGLE
}

async function generateNotificationsForNewMessagePrivateConversations({
  conversation,
  message
}: {
  conversation: Conversation;
  message: Message;
}): Promise<NotificationBundle[]> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const now = Date.now();
  const bundles: NotificationBundle[] = [];
  const triggerEventId = message.id;
  const notificationType = NotificationType.chatMessage;
  const expirationDateMS = moment().add("180", "days").valueOf();

  if (conversation.conversationType !== CONVERSATION_TYPES.accounts) {
    return bundles;
  }

  const [participantAccountsTemp, participantAccountPrivatesTemp] = await Promise.all([
    h.Account.getDocs(Object.keys(conversation.accounts)),
    h.AccountPrivate.getDocs(Object.keys(conversation.accounts))
  ]);
  const participantAccounts = _.compact(participantAccountsTemp);
  const participantAccountPrivates = _.compact(participantAccountPrivatesTemp);

  const messageCreatorAccount = pluck(participantAccounts, message.accountId);
  if (!messageCreatorAccount) {
    throw new Error("Unable to find account for message owner");
  }
  const creatorName = `${messageCreatorAccount.firstName} ${messageCreatorAccount.lastName}`;
  const accountsToNotify = participantAccounts.filter(n => n.id !== message.accountId);
  for (let i = 0; i < accountsToNotify.length; i++) {
    const account = accountsToNotify[i];
    const accountPrivate = participantAccountPrivates.find(ap => ap.id === account.id);
    const locale = accountPrivate?.communicationLocale ?? "en-us";
    const notCreatorAccounts = participantAccounts.filter(t => t.id !== account.id);
    const notificationId = shortid();

    let title: string;
    let body: string;
    if (notCreatorAccounts.length === 1) {
      title = messageCreatorAccount.firstName;
      body = message.text
        ? message.text
        : message.pollId
        ? translate({ defaultMessage: `Uploaded a poll`, serverLocale: locale })
        : message.imageUri
        ? translate({ defaultMessage: `Uploaded an image`, serverLocale: locale })
        : translate({ defaultMessage: `Uploaded a video`, serverLocale: locale });
    } else if (notCreatorAccounts.length === 2) {
      title =
        notCreatorAccounts.map(a => a.firstName).join(", ") + ` ${translate({ defaultMessage: "& you", serverLocale: locale })}`;
      body = message.text
        ? `@${creatorName}: ${message.text}`
        : message.pollId
        ? `@${creatorName} ${translate({ defaultMessage: "uploaded a poll", serverLocale: locale })}`
        : `@${creatorName} ${
            message.imageUri
              ? translate({ defaultMessage: `Uploaded an image`, serverLocale: locale })
              : translate({ defaultMessage: `Uploaded a video`, serverLocale: locale })
          }`;
    } else {
      const namedAccounts = notCreatorAccounts.map(a => a.firstName).slice(0, 3);
      const othersNumber = participantAccounts.length - namedAccounts.length;
      title = namedAccounts.join(", ") + ` & ${othersNumber} others`;
      body = message.text
        ? `@${creatorName}: ${message.text}`
        : message.pollId
        ? `@${creatorName} ${translate({ defaultMessage: "uploaded a poll", serverLocale: locale })}`
        : `@${creatorName} ${
            message.imageUri
              ? translate({ defaultMessage: `Uploaded an image`, serverLocale: locale })
              : translate({ defaultMessage: `Uploaded a video`, serverLocale: locale })
          }`;
    }

    bundles.push({
      id: notificationId,
      conversationType: conversation.conversationType,
      accountId: account.id,
      type: notificationType,
      triggerEventId,
      pushNotificationData: {
        id: notificationId,
        title,
        body,
        conversationId: conversation.id,
        messageId: message.id,
        triggerEventId,
        type: notificationType,
        pushNotificationSettingToRespect: PushNotificationSettingToRespect.ALWAYS_SEND,
        sendingAccountId: message.accountId
      },
      realTimeNotification: {
        id: notificationId,
        e: expirationDateMS,
        d: now,
        cId: conversation.id,
        t: notificationType,
        sAId: message.accountId
      }
    });
  }

  return bundles;
  // SERVER_ONLY_TOGGLE
}

async function generateNotificationsForNewMessageTeamConversations({
  conversation,
  message,
  selfAccountId
}: {
  conversation: Conversation;
  message: Message;
  selfAccountId: AccountId;
}): Promise<NotificationBundle[]> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const requests: NotificationBundle[] = [];
  const triggerEventId = message.id;
  const expirationDateMS = moment().add("180", "days").valueOf();

  if (conversation.conversationType !== CONVERSATION_TYPES.team) {
    return requests;
  }

  let team = await h.Team.getDoc(conversation.teamId);
  if (!team) {
    throw new Error("Unable to find team");
  }

  let participantAccountIds: string[] = await conversation__server__accountIds({
    conversationId: conversation.id,
    selfAccountId: selfAccountId
  });
  let participantAccountPrviates = _.compact(await h.AccountPrivate.getDocs(participantAccountIds));
  let accountsIdsToNotify = participantAccountIds.filter(id => id !== message.accountId);
  const messageCreatorAccount = await h.Account.getDoc(message.accountId);
  if (!messageCreatorAccount) {
    throw new Error("Unable to find message creators accounts");
  }
  const creatorName = `${messageCreatorAccount.firstName} ${messageCreatorAccount.lastName}`;

  let notificationId = shortid();

  const now = Date.now();

  for (let i = 0; i < accountsIdsToNotify.length; i++) {
    let accountId = accountsIdsToNotify[i];
    const locale = participantAccountPrviates.find(ap => ap.id === accountId)?.communicationLocale ?? "en-us";
    // tslint:disable-next-line: no-non-null-assertion
    let title = `${team.name} ${getPrettyConversationType(conversation.teamConversationType!, locale)}`;
    let body = message.text
      ? `@${creatorName}: ${message.text}`
      : message.pollId
      ? `@${creatorName} ${translate({ defaultMessage: "uploaded a poll", serverLocale: locale })}`
      : `@${creatorName} ${
          message.imageUri
            ? translate({ defaultMessage: `Uploaded an image`, serverLocale: locale })
            : translate({ defaultMessage: `Uploaded a video`, serverLocale: locale })
        }`;
    requests.push({
      accountId,
      conversationType: CONVERSATION_TYPES.team,
      id: notificationId,
      triggerEventId,
      type: NotificationType.chatMessage,
      realTimeNotification: {
        t: NotificationType.chatMessage,
        d: now,
        cId: conversation.id,
        id: notificationId,
        e: expirationDateMS,
        sAId: message.accountId
      },
      pushNotificationData: {
        body,
        title,
        conversationId: conversation.id,
        id: notificationId,
        messageId: message.id,
        pushNotificationSettingToRespect: PushNotificationSettingToRespect.teamAndOrgChannels,
        triggerEventId,
        type: NotificationType.chatMessage,
        sendingAccountId: message.accountId
      }
    });
  }

  return requests;
  // SERVER_ONLY_TOGGLE
}

async function generateNotificationsForNewMessageOrgConversations({
  conversation,
  message
}: {
  conversation: Conversation;
  message: Message;
}): Promise<NotificationBundle[]> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const requests: NotificationBundle[] = [];

  if (conversation.conversationType !== CONVERSATION_TYPES.org) {
    return requests;
  }

  const triggerEventId = message.id;
  const expirationDateMS = moment().add("180", "days").valueOf();

  let org = await h.Org.getDoc(conversation.orgId);
  if (!org) {
    throw new Error("Unable to find org");
  }
  let orgTeams = await h.Team.query({ where: [{ deletedAtMS: ["==", 0] }, { orgId: ["==", conversation.orgId] }] });

  const participantAccountIds = getListOfAccountIdsForOrgChatChannel({
    org,
    orgTeams: orgTeams.docs,
    orgFilters: conversation.orgFilters
  });

  const [participantAccountsTemp, participantAccountPrivatesTemp] = await Promise.all([
    h.Account.getDocs(participantAccountIds),
    h.AccountPrivate.getDocs(participantAccountIds)
  ]);
  const participantAccounts = _.compact(participantAccountsTemp);
  const participantAccountPrivates = _.compact(participantAccountPrivatesTemp);

  const messageCreatorAccount = pluck(participantAccounts, message.accountId);
  const creatorName = `${messageCreatorAccount.firstName} ${messageCreatorAccount.lastName}`;

  let notificationId = shortid();
  const now = Date.now();

  let title = `${org.shortName} - ${conversation.title}`;

  let accountsToNotify = participantAccounts.filter(account => account.id !== message.accountId);

  for (let i = 0; i < accountsToNotify.length; i++) {
    let accountToNotify = accountsToNotify[i];
    const locale = participantAccountPrivates.find(ap => ap.id === accountToNotify.id)?.communicationLocale ?? "en-us";

    let body = message.text
      ? `@${creatorName}: ${message.text}`
      : message.pollId
      ? `@${creatorName} ${translate({ defaultMessage: "uploaded a poll", serverLocale: locale })}`
      : `@${creatorName} ${
          message.imageUri
            ? translate({ defaultMessage: `Uploaded an image`, serverLocale: locale })
            : translate({ defaultMessage: `Uploaded a video`, serverLocale: locale })
        }`;
    requests.push({
      accountId: accountToNotify.id,
      conversationType: CONVERSATION_TYPES.team,
      id: notificationId,
      triggerEventId,
      type: NotificationType.chatMessage,
      realTimeNotification: {
        t: NotificationType.chatMessage,
        d: now,
        cId: conversation.id,
        id: notificationId,
        e: expirationDateMS,
        sAId: message.accountId
      },
      pushNotificationData: {
        body,
        title,
        conversationId: conversation.id,
        messageId: message.id,
        id: notificationId,
        pushNotificationSettingToRespect: PushNotificationSettingToRespect.teamAndOrgChannels,
        triggerEventId,
        type: NotificationType.chatMessage,
        sendingAccountId: message.accountId
      }
    });
  }

  return requests;
  // SERVER_ONLY_TOGGLE
}

function getPrettyConversationType(type: TEAM_CONVERSATION_TYPES, locale: string) {
  if (type === TEAM_CONVERSATION_TYPES.all) {
    return translate.common(locale).Chat;
  } else if (type === TEAM_CONVERSATION_TYPES.alerts) {
    return translate.common(locale).Alerts;
  } else if (type === TEAM_CONVERSATION_TYPES.team) {
    return translate.common(locale).Team;
  } else if (type === TEAM_CONVERSATION_TYPES.staff) {
    return translate.common(locale).Staff;
  } else if (type === TEAM_CONVERSATION_TYPES.players) {
    return translate.common(locale).Players;
  } else {
    return "";
  }
}

// i18n certified - complete
