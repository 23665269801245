import * as express from "express";
import _ from "lodash";
import {
  CONVERSATION_TYPES,
  NotificationBundle,
  NotificationType,
  CalendarEntry,
  CALENDAR_ENTRY_TYPES,
  Team,
  AccountId,
  PlayerBundleId,
  PlayerBundle__AccountType,
  PushNotificationSettingToRespect,
  ActionRequest,
  PlayerBundle,
  Account,
  LowPriorityNotificationDetailCollectionName,
  LowPriorityNotificationDetail,
  ActionRequestId,
  Team__StaffTypes,
  LowPriorityNotificationDetailType,
  PKShootoutScore,
  StartedSoccerGame,
  MVPVotingMode,
  SoccerStatModes,
  JoinTeamErrorCodes,
  TEAM_PERMISSIONS
} from "@ollie-sports/models";
import { processNotificationBundles } from "./notification/notification.plumbing";
import moment from "moment";
import { generatePushID } from "../internal-utils/firebaseId";
import { getUniversalHelpers } from "../helpers";
import { fetchAccountIdsOnSquad } from "../internal-utils/team-utils";
import shortid from "shortid";
import { validateToken } from "../internal-utils/server-auth";
import { player__client__rawPlayersOnATeam } from "../api/player.api";
import { teamExtractPlayerIdsOnSquad } from "../compute/team.compute";
import { combineArrayWithCommasAndAnd, fetchAccountPrivatesCached, getTranslatedDayOfWeek, ObjectValues } from "../utils";
import { calendarEntry__server__addAttendanceReminder, calendarEntry__server__getAccountIdsForOrgEvent } from "./calendarEntry";
import { translate } from "@ollie-sports/i18n";
import { getBodyAndTitleForProfileRequest } from "../utils/player-bundle-utils";
import { Team__StaffPresets } from "../constants";
import { ServerThisContext } from "@ollie-sports/react-bifrost";
import { compute } from "..";

interface BaseNotificationInfo {
  title: string;
  contents: string;
}

export async function notification__server__triggerRemindersForCalendarEntry(
  this: ServerThisContext,
  p: {
    calendarEntryId: string;
    requesterAccountId: string;
  }
) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const entry = await h.CalendarEntry.getDoc(p.calendarEntryId);

  if (!entry) {
    throw new Error("Invalid calendar entry id requested! " + p.calendarEntryId);
  }

  const team = await h.Team.getDoc(entry.teamId);

  if (!team) {
    throw new Error("Invalid calendar entry with team id that cannot be found! " + entry.teamId);
  }

  let players = await player__client__rawPlayersOnATeam({ teamId: team.id });

  if (entry.squad) {
    const squadPlayerIds = teamExtractPlayerIdsOnSquad(team, entry.squad);
    players = players.filter(player => squadPlayerIds.includes(player.id));
  }

  //filter out players who have already set their attendance
  players = players.filter(pl => typeof entry.attendance?.[pl.id]?.coming !== "boolean");

  const pbIds = players.map(pl => pl.linkedPlayerBundleId).filter((a): a is string => !!a);

  const pbsTemp = await h.PlayerBundle.getDocs(pbIds);
  const pbs: PlayerBundle[] = [];
  pbsTemp.forEach(pb => {
    if (pb) {
      pbs.push(pb);
    }
  });

  const accountIds: string[] = [];
  pbs.forEach(pb => {
    Object.keys(pb.managingAccounts || {}).forEach(accId => {
      if (accId !== p.requesterAccountId) {
        accountIds.push(accId);
      }
    });
  });
  const accountPrivates = await fetchAccountPrivatesCached({ accountIds });

  const communicationLocales = _.uniq(accountPrivates.map(ap => ap.communicationLocale));

  const triggerEventId = entry.id + Date.now() + "-reminder";
  const routerPath = `main/events/event/${entry.id}`;

  const now = Date.now();
  const expireAtMS = moment().add(1, "days").valueOf();
  const nId = shortid();
  const { prettyCalendarEntryStrings } = await import("../compute/calendarEntry.compute");

  const lpNotificationsByLocale: Record<string, LowPriorityNotificationDetail> = communicationLocales.reduce((acc, locale) => {
    const entryStrings = prettyCalendarEntryStrings(entry, locale);

    let requesterRole = team.accounts[p.requesterAccountId]?.staffTitle ?? Team__StaffTypes.teamAdmin;

    if (requesterRole === Team__StaffTypes.staffMember) {
      requesterRole = Team__StaffTypes.teamAdmin;
    }

    const requesterRoleString = Team__StaffPresets(this.locale)[requesterRole].staffTitle.toLowerCase();

    const msgBody = translate(
      {
        defaultMessage: `Your {requesterRoleString} requests you mark your attendance for the {contextualTitle} scheduled for {fullTime}`,
        serverLocale: locale
      },
      { requesterRoleString, contextualTitle: entryStrings.contextualTitle, fullTime: entryStrings.fullTime }
    );
    const msgTitle = `${translate({ defaultMessage: "Coming?", serverLocale: locale })} ${capitalize(
      entryStrings.contextualTitle
    )}`;

    const lp: LowPriorityNotificationDetail = {
      body: msgBody,
      title: msgTitle,
      id: h.LowPriorityNotificationDetail.generateId(),
      routerPath,
      createdAtMS: now,
      type: LowPriorityNotificationDetailType.attendanceReminder,
      expireAtMS
    };
    acc[locale] = lp;
    return acc;
  }, {} as Record<string, LowPriorityNotificationDetail>);

  await Promise.all(
    Object.values(lpNotificationsByLocale).map(lp => {
      return h.LowPriorityNotificationDetail.add({ doc: lp });
    })
  );

  const notificationBundles: NotificationBundle[] = _.compact(
    _.uniq(accountIds)
      .filter(aid => aid !== p.requesterAccountId)
      .map(accId => {
        const communicationLocale = accountPrivates.find(ap => ap.id === accId)?.communicationLocale ?? "en-us";
        const lpnd = lpNotificationsByLocale[communicationLocale];
        if (lpnd) {
          return {
            type: NotificationType.lowPriorityNotification,
            id: nId,
            accountId: accId,
            triggerEventId,
            pushNotificationData: {
              body: lpnd.body,
              title: lpnd.title,
              id: nId,
              pushNotificationSettingToRespect: PushNotificationSettingToRespect.ALWAYS_SEND,
              type: NotificationType.lowPriorityNotification,
              triggerEventId,
              routerPath,
              lowPriorityNotificationDetailType: lpnd.type
            },
            realTimeNotification: {
              d: now,
              e: expireAtMS,
              id: nId,
              t: NotificationType.lowPriorityNotification,
              lpId: lpnd.id
            }
          };
        }
        return null;
      })
  );

  await processNotificationBundles({ notificationBundles });

  await calendarEntry__server__addAttendanceReminder({
    accountId: p.requesterAccountId,
    calendarEntryId: p.calendarEntryId
  });
  // SERVER_ONLY_TOGGLE
}

notification__server__triggerRemindersForCalendarEntry.auth = (req: any) => {
  validateToken(req);
  // Make sure token in valid
  // Make sure req.body.selfAccountId matches token.uid
};

export async function notification__server__triggerForEditedCalendarEntry(p: {
  selfAccountId: string;
  calendarEntryId: string;
  recurrenceSetId?: string;
  diff: {
    updated: Partial<CalendarEntry>;
    old: Partial<CalendarEntry>;
  };
  eventType: CALENDAR_ENTRY_TYPES;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  p.diff.updated = convertMagicDeletesToUndefined(p.diff.updated);

  if (!_.isEqual(Object.keys(p.diff.updated).sort(), Object.keys(p.diff.old).sort())) {
    console.error(p.diff.updated, p.diff.old);
    throw new Error("Old diff must have identical keys to updated diff");
  }

  const calendarEntry = await h.CalendarEntry.getDoc(p.calendarEntryId);

  if (!calendarEntry) {
    return { success: false, errorMessage: "Invalid calendarEntryId supplied:" + p.calendarEntryId };
  }

  const team = calendarEntry.teamId ? await h.Team.getDoc(calendarEntry.teamId) : null;
  const org = calendarEntry.orgEventOrgId ? await h.Org.getDoc(calendarEntry.orgEventOrgId) : null;
  const teamOrg = team?.orgId ? await h.Org.getDoc(team.orgId) : null;

  if (calendarEntry.teamId && !team) {
    return { success: false, errorMessage: "A calendarEntry with an invalid teamId was supplied:" + p.calendarEntryId };
  }
  if (calendarEntry.orgEventOrgId && !org) {
    return { success: false, errorMessage: "A calendarEntry with an invalid orgId was supplied:" + p.calendarEntryId };
  }

  const teamPerm1 = team
    ? (teamOrg && compute.isOrgAdmin([teamOrg], p.selfAccountId, teamOrg.id)) ||
      compute.hasPermissionOnTeam({ team, accountId: p.selfAccountId, permission: TEAM_PERMISSIONS.manageEvents })
    : false;
  const teamPerm2 = team
    ? ((teamOrg && compute.isOrgAdmin([teamOrg], p.selfAccountId, teamOrg.id)) ||
        compute.hasPermissionOnTeam({ team, accountId: p.selfAccountId, permission: TEAM_PERMISSIONS.recordStats })) &&
      (p.eventType === CALENDAR_ENTRY_TYPES.game || p.eventType === CALENDAR_ENTRY_TYPES.scrimmage)
    : false;

  if (calendarEntry.teamId && !teamPerm1 && !teamPerm2) {
    return { success: false, errorMessage: "User does not have permission to create notification" };
  }

  if (calendarEntry.orgEventOrgId && !org?.accounts[p.selfAccountId]?.exists) {
    return { success: false, errorMessage: "User does not have permission to create notification" };
  }

  const now = Date.now();

  let accountIds = calendarEntry.teamId
    ? Object.keys(team?.accounts ?? {})
    : await calendarEntry__server__getAccountIdsForOrgEvent({ calendarEntry });
  if (calendarEntry.teamId && team && calendarEntry.squad) {
    accountIds = await fetchAccountIdsOnSquad({ team: team, squad: calendarEntry.squad, squadSubset: "all" });
  }

  const triggerEventId = calendarEntry.id + Date.now();

  const { prettyCalendarEntryDateStrings, prettyCalendarEntryStrings } = await import("../compute/calendarEntry.compute");

  const accountPrivates = await fetchAccountPrivatesCached({ accountIds });

  const communicationLocales = _.uniq(accountPrivates.map(ap => ap.communicationLocale));

  const lpNotificationsByLocale: Record<string, LowPriorityNotificationDetail> = communicationLocales.reduce((acc, locale) => {
    const prettyStrings = prettyCalendarEntryStrings(calendarEntry, locale);
    const dateStr = prettyStrings.shortDate;
    const typeStr = prettyStrings.type;

    const diffStrings: string[] = [];
    //Canceled
    if (p.diff.old.canceled !== undefined) {
      diffStrings.push(
        p.diff.updated.canceled
          ? translate({ defaultMessage: "The event was canceled", serverLocale: locale })
          : translate({ defaultMessage: "The event was un-canceled", serverLocale: locale })
      );
    }

    if ("jerseyColor" in p.diff.updated) {
      diffStrings.push(
        p.diff.updated.jerseyColor
          ? translate(
              { defaultMessage: `Jersey color changed to {color}.`, serverLocale: locale },
              { color: p.diff.updated.jerseyColor }
            )
          : translate({ defaultMessage: "The jersey color was removed.", serverLocale: locale })
      );
    }

    if ("eventNotes" in p.diff.updated) {
      diffStrings.push(
        p.diff.updated.eventNotes
          ? translate(
              { defaultMessage: `A note was added "{notes}"`, serverLocale: locale },
              { notes: p.diff.updated.eventNotes }
            )
          : translate({ defaultMessage: "A note on the event was removed.", serverLocale: locale })
      );
    }

    if ("location" in p.diff.updated) {
      diffStrings.push(
        p.diff.updated.location && p.diff.updated.location.venue
          ? translate(
              { defaultMessage: `Location changed to {venue}`, serverLocale: locale },
              { venue: p.diff.updated.location.venue }
            )
          : //tslint:disable-next-line no-non-null-assertion
            translate(
              { defaultMessage: `The event will no longer be held at {venue}.`, serverLocale: locale },
              { venue: p.diff.old.location!.venue ?? "" }
            )
      );
    }

    if (p.diff.updated.locationDetails) {
      diffStrings.push(
        p.diff.updated.locationDetails
          ? translate(
              { defaultMessage: `A note was added to the location "{locationDetails}"`, serverLocale: locale },
              { locationDetails: p.diff.updated.locationDetails }
            )
          : translate({ defaultMessage: "A note on the event location was removed.", serverLocale: locale })
      );
    }

    //New time
    let hasDayDiffString = false;
    if (
      p.diff.old.startDateTime !== undefined ||
      p.diff.old.arrivalDateTime !== undefined ||
      p.diff.old.endDateTime !== undefined
    ) {
      const isSameDayChange =
        !p.diff.old.startDateTime ||
        (!!p.diff.old.startDateTime &&
          moment(p.diff.old.startDateTime).startOf("day").valueOf() ===
            moment(p.diff.updated.startDateTime).startOf("day").valueOf());

      const oldDateStrings = prettyCalendarEntryDateStrings(
        {
          startDateTime: p.diff.old.startDateTime || calendarEntry.startDateTime,
          endDateTime: p.diff.old.endDateTime || calendarEntry.endDateTime,
          arrivalDateTime: p.diff.old.arrivalDateTime,
          timezone: p.diff.old.timezone || calendarEntry.timezone
        },
        locale
      );

      const newDateStrings = prettyCalendarEntryDateStrings(
        {
          startDateTime: p.diff.updated.startDateTime || calendarEntry.startDateTime,
          endDateTime: p.diff.updated.endDateTime || calendarEntry.endDateTime,
          arrivalDateTime: p.diff.updated.arrivalDateTime,
          timezone: p.diff.updated.timezone || calendarEntry.timezone
        },
        locale
      );

      // If the start time changed, just notify of that, and the arrival and end time changes will be implied
      if (p.diff.old.startDateTime !== p.diff.updated.startDateTime) {
        if (!isSameDayChange) {
          if (!p.diff.old.startDateTime || !p.diff.old.endDateTime) {
            //Should never happen, I think?
            diffStrings.push(
              translate(
                { defaultMessage: `The new time is {fullTime}`, serverLocale: locale },
                { fullTime: prettyStrings.fullTime }
              )
            );
          } else {
            hasDayDiffString = true;
            let oldStartDateStr = oldDateStrings.fullTime;
            let newStartDateStr = newDateStrings.fullTime;
            diffStrings.push(
              translate(
                { defaultMessage: `The time was changed from {oldStartDateStr} to {newStartDateStr}`, serverLocale: locale },
                {
                  oldStartDateStr,
                  newStartDateStr
                }
              )
            );
          }
        } else {
          diffStrings.push(
            translate(
              { defaultMessage: `The time was changed from {oldTime} to {newTime}`, serverLocale: locale },
              { oldTime: oldDateStrings.shortTime, newTime: newDateStrings.shortTime }
            )
          );
        }
      }
      // If the start time didn't change, check if the arrival or end time changed
      else {
        if (p.diff.old.arrivalDateTime !== p.diff.updated.arrivalDateTime) {
          let oldArrivalTimeStr = oldDateStrings.arriveOClock ?? "";
          let newArrivalTimeStr = newDateStrings.arriveOClock ?? "";
          diffStrings.push(
            translate(
              {
                defaultMessage: `The arrival time was changed from {oldArrivalTimeStr} to {newArrivalTimeStr}`,
                serverLocale: locale
              },
              { oldArrivalTimeStr, newArrivalTimeStr }
            )
          );
        }
        if (p.diff.old.endDateTime !== p.diff.updated.endDateTime) {
          const endDateSameDayChange =
            moment(p.diff.old.endDateTime).startOf("day").valueOf() ===
            moment(p.diff.updated.endDateTime).startOf("day").valueOf();
          let oldEndDateStr = endDateSameDayChange ? `${oldDateStrings.endOClock}` : `${oldDateStrings.endFullTime}`;
          let newEndDateStr = endDateSameDayChange ? `${newDateStrings.endOClock}` : `${newDateStrings.endFullTime}`;
          diffStrings.push(
            translate(
              { defaultMessage: `The end time was changed from {oldEndDateStr} to {newEndDateStr}`, serverLocale: locale },
              { oldEndDateStr, newEndDateStr }
            )
          );
        }
      }
    }

    const notificationDiffString = diffStrings.length ? `\n${diffStrings.join("\n")}` : "";

    let info: BaseNotificationInfo;
    const name = calendarEntry.teamId ? team?.shortName ?? "" : org?.shortName ?? "";
    if (p.recurrenceSetId) {
      const potentialDateString = hasDayDiffString
        ? ""
        : `, ${translate({ defaultMessage: "starting on {dateStr}", serverLocale: locale }, { dateStr })},`;
      info = {
        title: `${name} - ${translate({ defaultMessage: "Repeating {typeStr} updated", serverLocale: locale }, { typeStr })}`,
        contents: `${translate(
          { defaultMessage: "A repeating {extraString} was modified", serverLocale: locale },
          { extraString: `${typeStr}${potentialDateString}` }
        )}.${notificationDiffString}`
      };
    } else {
      let sentenceStart: string;
      if (
        calendarEntry.calendarEntryType === CALENDAR_ENTRY_TYPES.game ||
        calendarEntry.calendarEntryType === CALENDAR_ENTRY_TYPES.scrimmage
      ) {
        sentenceStart = translate(
          { defaultMessage: `A {typeStr} with {opponentName}`, serverLocale: locale },
          { typeStr, opponentName: calendarEntry.opponentName }
        );
      } else if (calendarEntry.calendarEntryType === "other") {
        sentenceStart = `${translate({ defaultMessage: "An event", serverLocale: locale })} "${calendarEntry.title}"`;
      } else {
        sentenceStart = translate.common(locale).Practice;
      }

      const potentialDateString = hasDayDiffString
        ? ""
        : ` ${translate(
            { defaultMessage: "on {dateStr}", description: `used with a date, like "on June 1st"`, serverLocale: locale },
            { dateStr }
          )}`;

      info = {
        title: `${name} - ${translate({ defaultMessage: "{type} updated", serverLocale: locale }, { type: prettyStrings.type })}`,
        contents: `${translate(
          { defaultMessage: "{extraText} was updated.", serverLocale: locale },
          { extraText: `${sentenceStart}${potentialDateString}` }
        )}${notificationDiffString}`
      };
    }

    const lp: LowPriorityNotificationDetail = {
      body: info.contents,
      title: info.title,
      id: h.LowPriorityNotificationDetail.generateId(),
      routerPath: `main/events/event/${calendarEntry.id}`,
      createdAtMS: Date.now(),
      type: LowPriorityNotificationDetailType.calendar,
      expireAtMS: moment().add(30, "days").valueOf()
    };
    acc[locale] = lp;
    return acc;
  }, {} as Record<string, LowPriorityNotificationDetail>);

  await Promise.all(
    Object.values(lpNotificationsByLocale).map(lp => {
      return h.LowPriorityNotificationDetail.add({ doc: lp });
    })
  );

  const notificationBundles: NotificationBundle[] = _.compact(
    accountIds
      .filter(aid => aid !== p.selfAccountId)
      .map(accountId => ({ accountId, id: generatePushID() }))
      .map(a => {
        const communicationLocale = accountPrivates.find(ap => ap.id === a.accountId)?.communicationLocale ?? "en-us";
        const lpnd = lpNotificationsByLocale[communicationLocale];
        if (lpnd) {
          return {
            type: NotificationType.lowPriorityNotification,
            id: a.id,
            accountId: a.accountId,
            triggerEventId,
            pushNotificationData: {
              body: lpnd.body,
              title: lpnd.title,
              id: a.id,
              triggerEventId,
              type: NotificationType.lowPriorityNotification,
              pushNotificationSettingToRespect: PushNotificationSettingToRespect.calendarEvents,
              routerPath: lpnd.routerPath,
              lowPriorityNotificationDetailType: lpnd.type
            },
            realTimeNotification: {
              e: lpnd.expireAtMS,
              d: now,
              id: a.id,
              t: NotificationType.lowPriorityNotification,
              lpId: lpnd.id
            }
          };
        }
        return null;
      })
  );

  await processNotificationBundles({
    notificationBundles
  });

  return { success: true };
  // SERVER_ONLY_TOGGLE
}
notification__server__triggerForEditedCalendarEntry.auth = (req: express.Request) => {
  // Make sure token in valid
  // Make sure req.body.selfAccountId matches token.uid
};

export async function notification__server__triggerForCreatedCalendarEntry(p: {
  selfAccountId: string;
  calendarEntryId: string;
  recurrenceSetInfo?: {
    recurrenceSetId: string;
    repeatDaysOfWeek: number[];
    endRepeatDateTime: string;
  };
  eventType: CALENDAR_ENTRY_TYPES;
}): Promise<{ success: boolean; errorMessage?: string }> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const calendarEntry = await h.CalendarEntry.getDoc(p.calendarEntryId);

  if (!calendarEntry) {
    return { success: false, errorMessage: "Invalid calendarEntryId supplied:" + p.calendarEntryId };
  }
  const team = calendarEntry.teamId ? await h.Team.getDoc(calendarEntry.teamId) : null;
  const org = calendarEntry.orgEventOrgId ? await h.Org.getDoc(calendarEntry.orgEventOrgId) : null;
  const teamOrg = team?.orgId ? await h.Org.getDoc(team.orgId) : null;

  if (calendarEntry.teamId && !team) {
    return { success: false, errorMessage: "A calendarEntry with an invalid teamId was supplied:" + p.calendarEntryId };
  }
  if (calendarEntry.orgEventOrgId && !org) {
    return { success: false, errorMessage: "A calendarEntry with an invalid orgEventOrgId was supplied:" + p.calendarEntryId };
  }

  const teamPerm1 = team
    ? (teamOrg && compute.isOrgAdmin([teamOrg], p.selfAccountId, teamOrg.id)) ||
      compute.hasPermissionOnTeam({ team, accountId: p.selfAccountId, permission: TEAM_PERMISSIONS.manageEvents })
    : false;
  const teamPerm2 = team
    ? ((teamOrg && compute.isOrgAdmin([teamOrg], p.selfAccountId, teamOrg.id)) ||
        compute.hasPermissionOnTeam({ team, accountId: p.selfAccountId, permission: TEAM_PERMISSIONS.recordStats })) &&
      (p.eventType === CALENDAR_ENTRY_TYPES.game || p.eventType === CALENDAR_ENTRY_TYPES.scrimmage)
    : false;

  if (calendarEntry.teamId && !teamPerm1 && !teamPerm2) {
    return { success: false, errorMessage: "User does not have permission to create notification" };
  }

  const orgPerm = org?.accounts[p.selfAccountId]?.exists;
  if (calendarEntry.orgEventOrgId && !orgPerm) {
    return { success: false, errorMessage: "User does not have permission to create notification" };
  }

  //Inline require to prevent circular dependency...
  const { prettyCalendarEntryStrings } = await import("../compute/calendarEntry.compute");

  let accountIds = calendarEntry.teamId
    ? Object.keys(team?.accounts ?? {})
    : await calendarEntry__server__getAccountIdsForOrgEvent({ calendarEntry });
  if (calendarEntry.teamId && team && calendarEntry.squad) {
    accountIds = await fetchAccountIdsOnSquad({ team: team, squad: calendarEntry.squad, squadSubset: "all" });
  }

  const now = Date.now();
  const triggerEventId = calendarEntry.id + now;

  const accountPrivates = await fetchAccountPrivatesCached({ accountIds });

  const communicationLocales = _.uniq(accountPrivates.map(ap => ap.communicationLocale));

  const lpNotificationsByLocale: Record<string, LowPriorityNotificationDetail> = communicationLocales.reduce((acc, locale) => {
    const prettyStrings = prettyCalendarEntryStrings(calendarEntry, locale);
    const typeStr = prettyStrings.type;
    const dateStr = calendarEntry.isAllDay
      ? prettyStrings.shortDate !== prettyStrings.endShortDate
        ? translate(
            { defaultMessage: `from {startDate} to {endDate}`, description: "For date ranges", serverLocale: locale },
            { startDate: prettyStrings.shortDate, endDate: prettyStrings.endShortDate }
          )
        : translate(
            { defaultMessage: `for {shortDate}`, description: `for a date, like "on June 1st"`, serverLocale: locale },
            { shortDate: prettyStrings.shortDate }
          )
      : prettyStrings.fullTime;

    let info: BaseNotificationInfo;
    const name = calendarEntry.teamId ? team?.shortName ?? "" : org?.shortName ?? "";
    if (p.recurrenceSetInfo) {
      const daysOfWeekArr = p.recurrenceSetInfo.repeatDaysOfWeek
        .map(d => moment().weekday(d).format("dddd"))
        .map(dayString => getTranslatedDayOfWeek(dayString, locale));

      const daysOfWeekStr = combineArrayWithCommasAndAnd(daysOfWeekArr, locale);

      info = {
        title:
          calendarEntry.calendarEntryType === CALENDAR_ENTRY_TYPES.practice
            ? `${name} - ${translate({
                defaultMessage: "Practice created",
                serverLocale: locale
              })}`
            : `${name} - ${translate(
                { defaultMessage: "{title} created", serverLocale: locale },
                { title: prettyStrings.title }
              )}`,
        contents:
          calendarEntry.calendarEntryType === CALENDAR_ENTRY_TYPES.other
            ? translate(
                {
                  defaultMessage: "An event repeating on {daysOfWeekStr} was created, starting on {dateStr}.",
                  serverLocale: locale
                },
                { daysOfWeekStr, dateStr }
              )
            : translate(
                {
                  defaultMessage: "A {typeStr} repeating on {daysOfWeekStr} was created, starting on {dateStr}.",
                  serverLocale: locale
                },
                { daysOfWeekStr, dateStr, typeStr }
              )
      };
    } else {
      let sentenceStart: string;
      if (
        calendarEntry.calendarEntryType === CALENDAR_ENTRY_TYPES.game ||
        calendarEntry.calendarEntryType === CALENDAR_ENTRY_TYPES.scrimmage
      ) {
        sentenceStart = translate(
          { defaultMessage: `A {typeStr} with {opponentName}`, serverLocale: locale },
          { typeStr, opponentName: calendarEntry.opponentName }
        );
      } else if (calendarEntry.calendarEntryType === "other") {
        sentenceStart = calendarEntry.isAllDay
          ? translate({ defaultMessage: `An all day event "{title}"`, serverLocale: locale }, { title: calendarEntry.title })
          : translate({ defaultMessage: `An event "{title}"`, serverLocale: locale }, { title: calendarEntry.title });
      } else {
        sentenceStart = translate({ defaultMessage: "A practice", serverLocale: locale });
      }

      info = {
        title: `${name} - ${translate({ defaultMessage: "{typeStr} created", serverLocale: locale }, { typeStr })}`,
        contents: calendarEntry.isAllDay
          ? translate(
              { defaultMessage: `{sentenceStart} was created {dateStr}`, serverLocale: locale },
              { sentenceStart, dateStr }
            )
          : translate(
              { defaultMessage: `{sentenceStart} was created on {dateStr}`, serverLocale: locale },
              { sentenceStart, dateStr }
            )
      };
    }

    const lp: LowPriorityNotificationDetail = {
      body: info.contents,
      title: info.title,
      id: h.LowPriorityNotificationDetail.generateId(),
      routerPath: `main/events/event/${calendarEntry.id}`,
      createdAtMS: Date.now(),
      type: LowPriorityNotificationDetailType.calendar,
      expireAtMS: moment().add(30, "days").valueOf()
    };
    acc[locale] = lp;
    return acc;
  }, {} as Record<string, LowPriorityNotificationDetail>);

  await Promise.all(
    Object.values(lpNotificationsByLocale).map(lp => {
      return h.LowPriorityNotificationDetail.add({ doc: lp });
    })
  );

  const notificationBundles: NotificationBundle[] = _.compact(
    accountIds
      .filter(aid => aid !== p.selfAccountId)
      .map(accountId => ({ accountId, id: generatePushID() }))
      .map(a => {
        const communicationLocale = accountPrivates.find(ap => ap.id === a.accountId)?.communicationLocale ?? "en-us";
        const lpnd = lpNotificationsByLocale[communicationLocale];
        if (lpnd) {
          return {
            type: NotificationType.lowPriorityNotification,
            id: a.id,
            accountId: a.accountId,
            triggerEventId,
            pushNotificationData: {
              body: lpnd.body,
              title: lpnd.title,
              id: a.id,
              triggerEventId,
              type: NotificationType.lowPriorityNotification,
              pushNotificationSettingToRespect: PushNotificationSettingToRespect.calendarEvents,
              routerPath: lpnd.routerPath,
              lowPriorityNotificationDetailType: lpnd.type
            },
            realTimeNotification: {
              e: lpnd.expireAtMS,
              d: now,
              id: a.id,
              t: NotificationType.lowPriorityNotification,
              lpId: lpnd.id
            }
          };
        }
        return null;
      })
  );

  await processNotificationBundles({
    notificationBundles
  });

  return { success: true };
  // SERVER_ONLY_TOGGLE
}
notification__server__triggerForCreatedCalendarEntry.auth = (req: express.Request) => {
  // Make sure token in valid
  // Make sure req.body.selfAccountId matches token.uid
};

// TODO FIRESTORE_LIFT FN EVALUATE
function convertMagicDeletesToUndefined(obj: any) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  if (typeof obj === "object" && obj != null) {
    Object.keys(obj).forEach(k => {
      obj[k] = convertMagicDeletesToUndefined(obj[k]);
    });
    return obj;
  } else if (obj === h._MagicDeleteValue) {
    return undefined;
  } else {
    return obj;
  }
}

export async function notification__server__triggerForNewStats(p: {
  selfAccountId: string;
  calendarEntryId: string;
  isGameMoreThan4HoursPastScheduledStartTime: boolean;
  score: {
    ownTeam: number;
    opponentTeam: number;
  };
  pkScore?: PKShootoutScore;
  game: StartedSoccerGame;
}): Promise<{ success: boolean; errorMessage?: string }> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const calendarEntry = await h.CalendarEntry.getDoc(p.calendarEntryId);

  if (!calendarEntry) {
    return { success: false, errorMessage: "Invalid calendarEntryId supplied:" + p.calendarEntryId };
  }

  if (
    !(
      calendarEntry.calendarEntryType === CALENDAR_ENTRY_TYPES.game ||
      calendarEntry.calendarEntryType === CALENDAR_ENTRY_TYPES.scrimmage
    )
  ) {
    return { success: false, errorMessage: "New Stats notifications can only occur on games and scrimmages" };
  }

  const team = await h.Team.getDoc(calendarEntry.teamId);

  if (!team) {
    return { success: false, errorMessage: "A calendarEntry with an invalid teamId was supplied:" + p.calendarEntryId };
  }

  if (!team.accounts?.[p.selfAccountId]?.additionalPermissions?.recordStats) {
    return { success: false, errorMessage: "User does not have permission to create notification" };
  }

  const now = Date.now();

  let accountIds = Object.keys(team.accounts);
  if (calendarEntry.squad) {
    accountIds = await fetchAccountIdsOnSquad({ team: team, squad: calendarEntry.squad, squadSubset: "all" });
  }

  const accountPrivates = await fetchAccountPrivatesCached({ accountIds });

  const triggerEventId = calendarEntry.id + Date.now();

  const notificationBundles: NotificationBundle[] = accountIds
    .filter(aid => aid !== p.selfAccountId)
    .map(accountId => ({ accountId, id: generatePushID() }))
    .map(a => {
      const locale = accountPrivates.find(ap => ap.id === a.accountId)?.communicationLocale ?? "en-us";

      const pkScoreText = `${
        p.pkScore
          ? ` (${
              p.pkScore.ownTeam > p.pkScore.opponentTeam
                ? translate({ defaultMessage: "won in pks", serverLocale: locale })
                : translate({ defaultMessage: "lost in pks", serverLocale: locale })
            } ${p.pkScore.ownTeam}-${p.pkScore.opponentTeam})`
          : ""
      }`;

      let info = p.isGameMoreThan4HoursPastScheduledStartTime
        ? {
            title: translate(
              { defaultMessage: `Stats completed for game against {opponentName}`, serverLocale: locale },
              { opponentName: calendarEntry.opponentName }
            ),
            contents: !p.game.areMVPsDisbaled
              ? translate({ defaultMessage: `Click to view stats and see who won the MVPs!`, serverLocale: locale })
              : translate({ defaultMessage: "Click to view stats!" })
          }
        : {
            title: `${translate.common(locale).Final}: ${team.shortName} ${p.score.ownTeam}-${p.score.opponentTeam} ${
              calendarEntry.opponentName
            }${pkScoreText}`,
            contents:
              !p.game.areMVPsDisbaled && !(p.game.votingMode === MVPVotingMode.none && p.game.statMode !== SoccerStatModes.team)
                ? translate({
                    defaultMessage: `Click to view stats and vote for MVPs! Voting closes in 2 hours.`,
                    serverLocale: locale
                  })
                : !p.game.areMVPsDisbaled && p.game.votingMode === MVPVotingMode.none
                ? translate({ defaultMessage: "Click to view stats and MVP winners!" })
                : translate({ defaultMessage: "Click to view stats!" })
          };
      return {
        type: NotificationType.statsReady,
        id: a.id,
        accountId: a.accountId,
        triggerEventId,
        pushNotificationData: {
          body: info.contents,
          title: info.title,
          calendarEntryId: calendarEntry.id,
          id: a.id,
          pushNotificationSettingToRespect: PushNotificationSettingToRespect.statsAndGame,
          teamId: team.id,
          triggerEventId,
          type: NotificationType.statsReady
        },
        realTimeNotification: {
          calId: calendarEntry.id,
          d: now,
          id: a.id,
          t: NotificationType.statsReady,
          tId: team.id,
          e: moment().add(30, "days").valueOf()
        }
      };
    });

  await processNotificationBundles({
    notificationBundles
  });

  return { success: true };
  // SERVER_ONLY_TOGGLE
}
notification__server__triggerForNewStats.auth = (req: express.Request) => {
  // Make sure token in valid
  // Make sure req.body.selfAccountId matches token.uid
};

export async function notification__server__triggerForProfileRequest(p: {
  requestingAccountId: AccountId;
  playerBundleId: PlayerBundleId;
  accountType: PlayerBundle__AccountType;
}): Promise<{ status: "fail"; code?: JoinTeamErrorCodes } | { status: "requestPending" } | { status: "success" }> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const now = Date.now();

  const r1 = await h.PlayerBundle.getDoc(p.playerBundleId);

  if (!r1) {
    console.warn("Attempting to generate notifications for granting access to a player bundle that does not exist.");
    return {
      status: "fail"
    };
  }

  let playerBundle = r1;

  // If requesting as an athlete, double check there is not already a self athlete
  if (
    p.accountType === PlayerBundle__AccountType.selfAthlete &&
    Object.values(playerBundle.managingAccounts ?? {}).some(a => a?.type === PlayerBundle__AccountType.selfAthlete)
  ) {
    return { status: "fail", code: JoinTeamErrorCodes.selfAthleteAlreadyExistsOnPlayerBundle };
  }

  const account = await h.Account.getDoc(p.requestingAccountId);

  if (!account) {
    console.warn("Attempting to generate notifications for granting profile access to an account that does not exist.");
    return {
      status: "fail"
    };
  }

  if (!playerBundle.managingAccounts) {
    console.warn(
      "Attempting to generate notifications for a granting access to a player bundle that does not have any managing accounts."
    );
    return {
      status: "fail"
    };
  }

  const existingRequests = (
    await h.ActionRequest.query({
      where: [
        { requestingAccountId: ["==", p.requestingAccountId] },
        { playerBundleId: ["==", p.playerBundleId] },
        { expirationDateMS: [">", Date.now()] },
        { status: ["==", "pending"] }
      ]
    })
  ).docs;

  if (existingRequests.length > 0) {
    console.warn(
      "Attempting to generate notifications for granting access to a player bundle for an account that has a pending request"
    );
    return {
      status: "requestPending"
    };
  }

  // Make sure the account does not already have access to the profile
  if (
    Object.keys(playerBundle.managingAccounts).find(id => {
      id === p.requestingAccountId;
    })
  ) {
    console.warn(
      "Attempting to generate notifications for a granting access to a player bundle that is already managed by the account requesting access."
    );
    return {
      status: "fail"
    };
  }

  const accountIds = Object.keys(playerBundle.managingAccounts);

  const accountPrivates = await fetchAccountPrivatesCached({ accountIds });

  const requestorName = `${account.firstName} ${account.lastName}`;
  const playerBundleName = `${playerBundle.virtualAthleteAccount.firstName} ${playerBundle.virtualAthleteAccount.lastName}`;

  const ar: ActionRequest = {
    requestingAccountId: p.requestingAccountId,
    playerBundleId: p.playerBundleId,
    accountType: p.accountType,
    expirationDateMS: moment().add(90, "days").valueOf(),
    id: h.ActionRequest.generateId(),
    status: "pending",
    createdAtMS: now
  };
  await h.ActionRequest.add({
    doc: ar
  });
  const triggerEventId = ar.id + Date.now();
  const notificationBundles: NotificationBundle[] = accountIds
    .map(accountId => ({ accountId, id: shortid() }))
    .map(a => {
      const locale = accountPrivates.find(ap => ap.id === a.accountId)?.communicationLocale ?? "en-us";
      const { body, title } = getBodyAndTitleForProfileRequest({
        accountType: p.accountType,
        playerBundleName,
        requestorName,
        locale
      });
      return {
        id: a.id,
        triggerEventId,
        accountId: a.accountId,
        type: NotificationType.actionRequest,
        pushNotificationData: {
          body: body,
          title: title,
          triggerEventId,
          id: a.id,
          actionRequestId: ar.id,
          type: NotificationType.actionRequest,
          pushNotificationSettingToRespect: PushNotificationSettingToRespect.ALWAYS_SEND
        },
        realTimeNotification: {
          id: a.id,
          e: ar.expirationDateMS,
          d: now,
          t: NotificationType.actionRequest,
          rId: ar.id
        }
      };
    });

  await processNotificationBundles({
    notificationBundles: notificationBundles
  });

  return {
    status: "success"
  };
  // SERVER_ONLY_TOGGLE
}

notification__server__triggerForProfileRequest.auth = (req: express.Request) => {
  validateToken(req);
  // Make sure token in valid
  // Make sure req.body.selfAccountId matches token.uid
};

export async function notification__server__triggerForProfileResponse(p: {
  playerBundle: PlayerBundle;
  requestingAccount: Account;
  approve: boolean;
  accountType: PlayerBundle__AccountType;
  respondingAccount: Account;
  actionRequestId: ActionRequestId;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const now = Date.now();

  const accountPrivate = (await fetchAccountPrivatesCached({ accountIds: [p.requestingAccount.id] }))[0];
  const locale = accountPrivate?.communicationLocale ?? "en-us";

  const body = p.approve
    ? translate(
        {
          defaultMessage: `Your request to manage {profileName}'s player profile has been approved by {responderName}.`,
          serverLocale: locale
        },
        {
          profileName: `${p.playerBundle.virtualAthleteAccount.firstName} ${p.playerBundle.virtualAthleteAccount.lastName}`,
          responderName: `${p.respondingAccount.firstName} ${p.respondingAccount.lastName}`
        }
      )
    : translate(
        {
          defaultMessage: `Your request to manage {profileName}'s player profile has been denied by {responderName}.`,
          serverLocale: locale
        },
        {
          profileName: `${p.playerBundle.virtualAthleteAccount.firstName} ${p.playerBundle.virtualAthleteAccount.lastName}`,
          responderName: `${p.respondingAccount.firstName} ${p.respondingAccount.lastName}`
        }
      );
  const title = p.approve
    ? translate({ defaultMessage: "Player Profile Request Approved", serverLocale: locale })
    : translate({ defaultMessage: "Player Profile Request Denied", serverLocale: locale });

  let notificationBundles: NotificationBundle[] = [];
  const notificationId = generatePushID();
  const triggerEventId = p.actionRequestId + Date.now();

  // Send a low priority notification to the account requesting access to notify of the response
  const lp: LowPriorityNotificationDetail = {
    body,
    title,
    createdAtMS: now,
    expireAtMS: moment().add(30, "days").valueOf(),
    id: h.LowPriorityNotificationDetail.generateId(),
    routerPath: "main/settings",
    type: LowPriorityNotificationDetailType.joinProfileResponse
  };

  await h.LowPriorityNotificationDetail.add({
    doc: lp
  });

  notificationBundles.push({
    type: NotificationType.lowPriorityNotification,
    id: notificationId,
    accountId: p.requestingAccount.id,
    triggerEventId,
    pushNotificationData: {
      body: body,
      title: title,
      id: notificationId,
      pushNotificationSettingToRespect: PushNotificationSettingToRespect.ALWAYS_SEND,
      type: NotificationType.lowPriorityNotification,
      triggerEventId,
      routerPath: "main/settings",
      lowPriorityNotificationDetailType: lp.type
    },
    realTimeNotification: {
      d: now,
      id: notificationId,
      t: NotificationType.lowPriorityNotification,
      e: lp.expireAtMS,
      lpId: lp.id
    }
  });

  const accountIds = Object.keys(p.playerBundle.managingAccounts || []);
  const accountPrivates = await fetchAccountPrivatesCached({ accountIds });
  const communicationLocales = _.uniq(accountPrivates.map(ap => ap.communicationLocale));

  const lpNotificationsByLocale: Record<string, LowPriorityNotificationDetail> = communicationLocales.reduce((acc, loc) => {
    const body2 = p.approve
      ? translate(
          {
            defaultMessage: `{respondingName} has approved {requestorName}'s request to link to {bundleName}'s profile.`,
            serverLocale: loc
          },
          {
            respondingName: `${p.respondingAccount.firstName} ${p.respondingAccount.lastName}`,
            requestorName: `${p.requestingAccount.firstName} ${p.requestingAccount.lastName}`,
            bundleName: `${p.playerBundle.virtualAthleteAccount.firstName} ${p.playerBundle.virtualAthleteAccount.lastName}`
          }
        )
      : translate(
          {
            defaultMessage: `{respondingName} has denied {requestorName}'s request to link to {bundleName}'s profile.`,
            serverLocale: loc
          },
          {
            respondingName: `${p.respondingAccount.firstName} ${p.respondingAccount.lastName}`,
            requestorName: `${p.requestingAccount.firstName} ${p.requestingAccount.lastName}`,
            bundleName: `${p.playerBundle.virtualAthleteAccount.firstName} ${p.playerBundle.virtualAthleteAccount.lastName}`
          }
        );
    const title2 = p.approve
      ? translate({ defaultMessage: `Profile Link Approved`, serverLocale: loc })
      : translate({ defaultMessage: `Profile Link Denied`, serverLocale: loc });

    const lp2: LowPriorityNotificationDetail = {
      body: body2,
      title: title2,
      createdAtMS: now,
      expireAtMS: moment().add(30, "days").valueOf(),
      id: h.LowPriorityNotificationDetail.generateId(),
      routerPath: `main/settings`,
      type: LowPriorityNotificationDetailType.joinProfileResponse
    };

    acc[locale] = lp;
    return acc;
  }, {} as Record<string, LowPriorityNotificationDetail>);

  await Promise.all(
    Object.values(lpNotificationsByLocale).map(lpn => {
      return h.LowPriorityNotificationDetail.add({ doc: lpn });
    })
  );

  const notificationBundles2: NotificationBundle[] = _.compact(
    accountIds
      .filter(aid => aid !== p.respondingAccount.id)
      .map(accountId => ({ accountId, id: shortid() }))
      .map(a => {
        const communicationLocale = accountPrivates.find(ap => ap.id === a.accountId)?.communicationLocale ?? "en-us";
        const lpnd = lpNotificationsByLocale[communicationLocale];
        if (lpnd) {
          return {
            type: NotificationType.lowPriorityNotification,
            id: a.id,
            accountId: a.accountId,
            triggerEventId,
            pushNotificationData: {
              body: lpnd.body,
              title: lpnd.title,
              id: a.id,
              pushNotificationSettingToRespect: PushNotificationSettingToRespect.ALWAYS_SEND,
              type: NotificationType.lowPriorityNotification,
              triggerEventId,
              routerPath: `main/settings`,
              lowPriorityNotificationDetailType: lp.type
            },
            realTimeNotification: {
              d: now,
              id: a.id,
              t: NotificationType.lowPriorityNotification,
              e: lpnd.expireAtMS,
              lpId: lpnd.id
            }
          };
        }
        return null;
      })
  );

  notificationBundles = [...notificationBundles, ...notificationBundles2];

  await processNotificationBundles({
    notificationBundles
  });

  return {
    status: "success"
  };
  // SERVER_ONLY_TOGGLE
}

notification__server__triggerForProfileResponse.auth = (req: express.Request) => {
  validateToken(req);
  // Make sure token in valid
  // Make sure req.body.selfAccountId matches token.uid
};

function capitalize(str: string) {
  return str.slice(0, 1).toUpperCase() + str.slice(1);
}

// i18n certified - complete
